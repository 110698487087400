.inputDiv {
  margin-top: 15px;
}

.inputDiv label {
  margin-bottom: 5px;
  font-size: 14px;
}

.verifyBtn {
  border: 1px solid #4a934a;
  width: 80%;
  background-color: #4a934a;
  height: 45px;
  color: #fff;
  margin: auto;
  border-radius: 40px;

  /* margin: 10px auto 0px auto; */
}

.btnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.mainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  flex-direction: column;
}

.recaptchaSec {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.recaptchaSec div {
  flex: 1;
  text-align: center;
}
.recaptchaSec button {
  border: 1px solid lightgray;
  background-color: lightgray;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addBtn {
  /* margin-bottom: 20px; */
}

.table_body td {
  font-size: 14px;
}
.table_body button {
  border: 1px solid red;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  border-radius: 5px;
  background-color: red;
  color: white;
}
.licenseImge {
  width: 150px;
  height: 150px;
  margin: 10px auto;
  border-radius: 150px;
}
.detailsHeading {
  border-bottom: 1.5px solid lightgray;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #4a934a;
}
.detailsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  margin-bottom: 7px;
}
.detailsRowValue {
  margin-left: 10px;
}
